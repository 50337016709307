<template>
  <div>
    <TTableAdvance
      :items="list"
      :fields="fields"
      store="accounting.credit_orders"
      resource="/accounting/account/credit"
      @click-clear-filter="clearFilter"
      enterable
    >
      <template #order_id="{ item }">
        <td>
          <TLink
            v-if="item.transactions.length"
            :to="
              lodash.getReferenceLink(
                'order',
                item.transactions[0].receipts[0].receiptable_id
              )
            "
            :content="item.transactions[0].receipts[0].receiptable_id"
          />
          <TMessageNotFound v-else />
        </td>
      </template>
      <template #customer="{ item }">
        <td>
          <TMessage :content="getCustomer(item)" noTranslate />
        </td>
      </template>
      <template #amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #outstanding_amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.outstanding_amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #paid_amount="{ item }">
        <td>
          <TMessageMoney
            :amount="item.paid_amount"
            :currency="item.wallet ? item.wallet.currency_id : ''"
          />
        </td>
      </template>
      <template #payment_due_date="{ item }">
        <td>
          <TMessageDateTime :content="item.payment_due_date" dateOnly />
        </td>
      </template>
      <template #payment_due_date-filter>
        <TInputDateTimeRange
          :value.sync="dateRange"
          @update:value="setPaymentDueDateFilter"
        />
      </template>
      <template #customer-filter>
        <SSelectCustomer
          :value.sync="filter['wallet.walletable_id']"
          @change="selectWalletable"
          noCustomLabel
          prependAll
        />
      </template>
      <template #order_id-filter>
        <TInputText
          :value.sync="filter.by_order"
          @update:value="filterChange"
          placeholder="Id"
        />
      </template>
    </TTableAdvance>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      fields: [
        { key: "_", _style: "width: 40px" },
        {
          key: "order_id",
          label: "Sale order",
          _classes: "",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "customer",
          label: "Customer",
          _classes: "",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "payment_due_date",
          label: "Payment due date",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
          sorter: true,
        },
        {
          key: "amount",
          label: "Amount",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "outstanding_amount",
          label: "Outstanding",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
        {
          key: "paid_amount",
          label: "Paid",
          _classes: "text-nowrap",
          _style: "width: 100px; min-width: 100px",
        },
      ],
      showModalCreate: false,
      filter: {},
      filter_between: {},
      dateRange: {
        start: null,
        end: null,
      },
    };
  },
  created() {
    if (!this.$route.query.walletable_id) this.filterChange();
  },
  watch: {
    walletable_id: {
      immediate: true,
      handler(id) {
        if (id) {
          this.filter["wallet.walletable_id"] = id;
          this.filterChange();
        }
      },
    },
  },
  computed: {
    ...mapGetters({
      list: "accounting.credit_orders.list",
    }),
    walletable_id() {
      if (!this.lodash.isEmpty(this.$route.query)) {
        return this.$route.query.walletable_id;
      }
      return "";
    },
  },
  methods: {
    filterChange() {
      const filter = this.lodash.mapKeys(this.filter, function (value, key) {
        return `filter[${key}]`;
      });
      const filterBetween = this.lodash.mapKeys(
        this.filter_between,
        function (value, key) {
          return `filter_between[${key}]`;
        }
      );
      const filterFields = this.lodash.pickBy({
        ...filter,
        ...filterBetween,
      });
      this.$store.dispatch(
        "accounting.credit_orders.apply-query",
        filterFields
      );
    },
    setPaymentDueDateFilter({ start, end }) {
      this.filter_between["payment_due_date"] = `${start},${end}`;
      this.filterChange();
    },
    clearFilter() {
      this.lodash.appendRouteQuery(this.$router, { query: {} });
      this.filter = {};
      this.filter_between = {};
      this.dateRange = {
        start: null,
        end: null,
      };
      this.filterChange();
    },
    selectWalletable(customer) {
      this.lodash.appendRouteQuery(this.$router, {
        query: { walletable_id: customer.id },
      });
    },
    getCustomer(item) {
      return item.wallet?.object
        ? item.wallet?.object.name
        : item.wallet?.walletable_id;
    },
  },
};
</script>
